import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v1"},[_c('div',{staticClass:"auth-inner"},[_c(VCard,{staticClass:"auth-card"},[_c(VCardTitle,{staticClass:"d-flex align-center justify-center py-7"},[_c('router-link',{staticClass:"brand-logo d-flex align-center",attrs:{"to":"/"}},[_c(VImg,{staticClass:"me-3 ",attrs:{"src":_vm.appLogo,"max-height":"120px","max-width":"120px","alt":"logo","contain":""}})],1)],1),_c(VRow,{staticClass:"auth-row ma-0"},[_c(VCol,{staticClass:"d-flex align-center auth-bg pb-0",attrs:{"lg":"12"}},[_c(VRow,[_c(VCol,{staticClass:"mx-auto",attrs:{"cols":"12","sm":"8","md":"6","lg":"12"}},[_c(VCard,{attrs:{"flat":""}},[_c(VCardText,[_c('p',{staticClass:"text-2xl font-weight-semibold text--primary mb-2"},[_vm._v(" "+_vm._s(_vm.message.title)+" ")]),_c('p',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.message.text)+" ")])]),_c(VCardActions,{staticClass:"d-flex justify-center align-center"},[_c('router-link',{staticClass:"d-flex align-center text-sm",attrs:{"to":{name:'auth-login'}}},[_c(VIcon,{attrs:{"size":"24","color":"primary"}},[_vm._v(" "+_vm._s(_vm.icons.mdiChevronLeft)+" ")]),_c('span',[_vm._v("Zurück zum Login")])],1)],1)],1)],1)],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }