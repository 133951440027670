<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">


      <v-card class="auth-card">

        <v-card-title class="d-flex align-center justify-center py-7">
          <!-- brand logo -->
          <router-link
            to="/"
            class="brand-logo d-flex align-center"
          >
            <v-img
              :src="appLogo"
              max-height="120px"
              max-width="120px"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>
          </router-link>
          <!--/ brand logo -->
        </v-card-title>

        <v-row class="auth-row ma-0">


          <v-col
            lg="12"
            class="d-flex align-center auth-bg pb-0"
          >
            <v-row>
              <v-col
                cols="12"
                sm="8"
                md="6"
                lg="12"
                class="mx-auto"
              >
                <v-card flat>
                  <v-card-text>
                    <p class="text-2xl font-weight-semibold text--primary mb-2">
                      {{message.title}}
                    </p>
                    <p class="mb-2">
                      {{message.text}}
                    </p>
                  </v-card-text>

                  <v-card-actions class="d-flex justify-center align-center">
                    <router-link
                      :to="{name:'auth-login'}"
                      class="d-flex align-center text-sm"
                    >
                      <v-icon
                        size="24"
                        color="primary"
                      >
                        {{ icons.mdiChevronLeft }}
                      </v-icon>
                      <span>Zurück zum Login</span>
                    </router-link>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>




    </div>
  </div>
</template>

<script>
import { mdiChevronLeft, mdiCheckOutline } from '@mdi/js'
import { ref,onMounted } from '@vue/composition-api'
import axios from "@axios";
import router from "@/router";
import themeConfig from "@themeConfig";

export default {
  setup() {

    const message = ref({});
    message.value = {
      title : "Aktivierung wird geprüft",
      text : "Bitte warten Sie"
    }

    onMounted(() => {
      axios
        .get('/api/aktivierung/'+router.currentRoute.params.hashKey)
        .then(response => {
          if(response.data.valid === true){
            message.value = {
              title : response.data.message.title,
              text : response.data.message.text
            }
          }
          else{
            response.data.errors.forEach(item=>{
              message.value = {
                title : item.errorTitle,
                text : item.errorText
              }
            })
          }
        })
        .catch(error => {
          console.log(error)
        })
    })

    return {
      message,
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
      icons: {
        mdiChevronLeft,
        mdiCheckOutline
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>
